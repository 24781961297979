import { ContentItemProperties } from '@/data/types/Slot';

const contentItems: ContentItemProperties[] = [
	{ emsName: 'FooterMiddle_1' },
	{ emsName: 'FooterMiddle_2' },
	{ emsName: 'FooterMiddle_3' },
	{ emsName: 'FooterMiddle_4' },
	{ emsName: 'FooterMiddle_5' },
	{ emsName: 'FooterBottom' },
];

export default contentItems;
