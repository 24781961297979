/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const footerContainerSX = (csrSession: boolean): SxProps<Theme> => ({
	borderRadius: '0',
	boxShadow: 3,
	alignSelf: 'stretch',
	justifySelf: 'flex-end',
	backgroundColor: 'component.footer.background',
	color: 'component.footer.text',

	img: {
		height: (theme) => theme.spacing(3),
	},

	'& .MuiTypography-root.MuiLink-root': {
		color: (theme) =>
			theme.palette.component.footer.link === false ? '' : 'component.footer.link',

		'&:hover': {
			textDecoration: 'underline',
		},
	},

	...(!csrSession && { mt: 'auto' }),
});
