import { FlowIfEnabled } from '@/components/blocks/FlexFlow';
import { ProgressIndicator } from '@/components/blocks/ProgressIndicator';
import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { GDPRDialog } from '@/components/content/Footer/parts/GDPRDialog';
import { footerContainerSX } from '@/components/content/Footer/styles/container';
import { useFooter } from '@/data/Content/Footer';
import { useModalPrivacyPolicy } from '@/data/Content/ModalPrivacyPolicy';
import { dFix, useSettings } from '@/data/Settings';
import { EMS_STORE_FEATURE } from '@/data/constants/flexFlowStoreFeature';
import { ID } from '@/data/types/Basic';
import { Box, Container, Grid, Paper } from '@mui/material';
import { FC } from 'react';

export const Footer: FC<{
	id: ID;
	clickAction?: () => void;
}> = ({ id }) => {
	const { settings } = useSettings();
	const { contentItems } = useFooter(id);
	const { data, loading, onSubmit, privacyNoticeVersion, privacyNoticeVersionValue } =
		useModalPrivacyPolicy();

	const acceptedSessionPrivacyPolicy =
		dFix(privacyNoticeVersion ?? '') === dFix(privacyNoticeVersionValue ?? '');
	return (
		<Paper
			component="footer"
			sx={footerContainerSX(settings?.csrSession)}
			{...(settings?.csrSession && { 'data-iframe-height': '' })}
		>
			<Box>
				<Container maxWidth="xl">
					<Grid container spacing={4} sx={{ paddingY: { xs: 4, md: 6, lg: 8 } }}>
						<Grid item xs={12} sm={12} md={6} lg={12 / 5}>
							<ContentRecommendation
								key={contentItems[0].emsName}
								id={`${id}-${contentItems[0].emsName}`}
								properties={contentItems[0]}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3} lg={12 / 5}>
							<ContentRecommendation
								key={contentItems[1].emsName}
								id={`${id}-${contentItems[1].emsName}`}
								properties={contentItems[1]}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3} lg={12 / 5}>
							<ContentRecommendation
								key={contentItems[2].emsName}
								id={`${id}-${contentItems[2].emsName}`}
								properties={contentItems[2]}
							/>
						</Grid>
						<Grid
							item
							md={6}
							sx={{
								display: {
									xs: 'none',
									md: 'block',
									lg: 'none',
								},
							}}
						></Grid>
						<Grid item xs={12} sm={6} md={3} lg={12 / 5}>
							<ContentRecommendation
								key={contentItems[3].emsName}
								id={`${id}-${contentItems[3].emsName}`}
								properties={contentItems[3]}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3} lg={12 / 5}>
							<ContentRecommendation
								key={contentItems[4].emsName}
								id={`${id}-${contentItems[4].emsName}`}
								properties={contentItems[4]}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<ContentRecommendation
								key={contentItems[5].emsName}
								id={`${id}-${contentItems[5].emsName}`}
								properties={contentItems[5]}
							/>
						</Grid>
					</Grid>
				</Container>
			</Box>
			{!acceptedSessionPrivacyPolicy ? (
				<FlowIfEnabled feature={EMS_STORE_FEATURE.CONSENT_OPTIONS}>
					{loading ? <ProgressIndicator /> : <GDPRDialog data={data} onSubmit={onSubmit} />}
				</FlowIfEnabled>
			) : null}
		</Paper>
	);
};
