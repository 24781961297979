/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2024.
 */

import { StackProps } from '@mui/material';

export const footerGDPRDialogTabsContentStack: StackProps = {
	px: 2,
	spacing: 2,
};
